/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import actionPlan from '/static/policies/Action-Plan-20232024.pdf';
import adultSafe from '/static/policies/Adult-Safeguarding-.pdf';
import breavement from '/static/policies/Bereavement-Compassionate-Leave-2025.pdf';
import bio from '/static/policies/Biodiversity.pdf';
import cctv from '/static/policies/CCTV-policy.pdf';
import childProtection from '/static/policies/Child-Protection-Policy.pdf';
import conduct from '/static/policies/Code-of-Conduct-2025.pdf';
import communityEngagement from '/static/policies/Community-Engagement.pdf';
import complaints from '/static/policies/Complaints-Procedure.pdf';
import cooption from '/static/policies/Co-Option-Procedure.pdf';
import councillorOfficer from '/static/policies/Councillor-officer-protocol-2025.pdf';
import crime from '/static/policies/Crime-Disorder.pdf';
import dignity from '/static/policies/Dignity-at-Work-Policy-2025.pdf';
import discipline from '/static/policies/Disciplinary-Policy-and-Procedure.pdf';
import retention from '/static/policies/Document-Retention-Policy.pdf';
import equality from '/static/policies/Equality-and-Diversity-Policy.pdf';
import financeRegs from '/static/policies/Financial-Regulations.pdf';
import financeReserves from '/static/policies/Financial-reserves-policy.pdf';
import grants from '/static/policies/Grants-Policy-2025.pdf';
import grievance from '/static/policies/Grievance-Policy-and-Procedure-.pdf';
import hAndS from '/static/policies/Health-and-Safety.pdf';
import ict from '/static/policies/ICT-policy.pdf';
import information from '/static/policies/Information-Data-Protection-Policy-2025.pdf';
import investment from '/static/policies/Investment-Strategy-policy.pdf';
import lone from '/static/policies/Lone-Working-Policy-2025.pdf';
import management from '/static/policies/Management-of-Transferable-Data.pdf';
import media from '/static/policies/Media-policy.pdf';
import memorial from '/static/policies/Memorial-Management-Policy.pdf';
import publication from '/static/policies/Publication-Scheme-2025.pdf';
import recording from '/static/policies/Recording-of-meetings-2025.pdf';
import removableMedia from '/static/policies/Removable-Media.pdf';
import schemeDelegation from '/static/policies/Scheme-of-Delegation.pdf';
import sickness from '/static/policies/Sickness-and-Absence-Policy.pdf';
import socialMedia from '/static/policies/Social-Media-Policy-2025.pdf';
import standingOrders from '/static/policies/Standing-Orders-with-Prejudicial-Interests.pdf';
import training from '/static/policies/Training-policy-2025.pdf';
import vexatious from '/static/policies/Vexatious-Complaints-Policy-2025.pdf';
import volunteer from '/static/policies/Volunteer-Policy-update-Oct24.pdf';
import riskManagement from '/static/policies/Risk-Management-Assessment.pdf';
import covid1 from '/static/policies/COVID-19-preparing-to-open-business.pdf';
import covid2 from '/static/policies/COVID-19-reopening-parish-office-risk-assessment.pdf';
import covid3 from '/static/policies/COVID-19-risk-assessment.pdf';
import covid4 from '/static/policies/COVID-19-reopening-play-areas.pdf';
import harassment from '/static/policies/Prevention-of-Bullying-Harassment-Sexual-Harassment-and-Victimisation-Policy.pdf';
import capability from '/static/policies/Capability-Policy-Procedure.pdf';
import probation from '/static/policies/Probation-Policy-Procedure.pdf';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Important Policies and Procedure"), "\n", React.createElement(_components.h2, null, "Policies and Procedures"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: actionPlan,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Action Plan"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: adultSafe,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Adult Safeguarding"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: bio,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Biodiversity Policy"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: breavement,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Bereavement/Compassionate Leave Policy"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: capability,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Capability Policy and Procedure"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: cctv,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : CCTV Policy"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: childProtection,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Child Protection Policy"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: conduct,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Code of Conduct"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: communityEngagement,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Community Engagement Policy"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: complaints,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Complaints Procedure"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: cooption,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Councillor Co-option"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: councillorOfficer,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Councillor Officer Protocol"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: crime,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Crime and Disorder Policy"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: dignity,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Dignity at Work Policy"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: discipline,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Disciplinary Policy & Procedure"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: retention,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Document Retention Policy"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: equality,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Equality & Diversity Policy"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: financeRegs,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Financial Regulations"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: financeReserves,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Financial Reserves Policy"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: "policies/freedom-of-information",
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "far fa-external-link-alt"
  }), " : Freedom of Information Data"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: grants,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Grants Policy"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: grievance,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Grievance Policy & Procedure"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: hAndS,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Health and Safety"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: ict,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : ICT Policy"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: information,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Information Data Protection Policy"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: investment,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Investment Strategy Policy"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: lone,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Lone Worker Policy"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: management,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Management of Transferable Data Policy"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: media,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Media Policy"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: memorial,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Memorial Management Policy"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: harassment,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Prevention of Bullying, Harassment, Sexual Harassment and Victimisation Policy"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: probation,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Probation Policy and Procedure"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: publication,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Publication Scheme"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: recording,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Recording of Council Meetings Policy"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: removableMedia,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Removable Media Policy"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: schemeDelegation,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Scheme of Delegation"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: sickness,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Sickness and Absence Policy"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: socialMedia,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Social Media Policy"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: standingOrders,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Standing Orders (with Prejudicial Interests)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: training,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Training Policy"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: vexatious,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Vexatious Complaints Policy"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: volunteer,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Volunteer Policy"), "\n"), "\n"), "\n", React.createElement(_components.h2, null, "Risk Assessments"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: covid1,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : COVID-19 preparing to open business"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: covid2,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : COVID-19 reopening parish office risk assessment"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: covid3,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : COVID-19 general risk assessment"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: covid4,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : COVID-19 reopening play areas"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: riskManagement,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Risk Management Assessment"), "\n"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
